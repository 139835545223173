import React, { useState } from "react";
import { Providers } from "./Providers";
import { Facilities } from "./Facilities";

const Tabs = {
	Provider: "provider",
	Facility: "facility",
};

export default () => {
	const [activeTab, setActiveTab] = useState(Tabs.Provider);

	const isActive = (tab) => (activeTab === tab ? "is-active" : "");

	const handleTabClick = (tab) => () => {
		setActiveTab(tab);
	};

	const TabContent = () => {
		switch (activeTab) {
			case Tabs.Provider:
				return <Providers />;
			case Tabs.Facility:
			default:
				return <Facilities />;
		}
	};

	return (
		<>
			<div className="container mt-2">
				<h1 className="is-align-center warning-message">
					This screen displays legacy data and shouldn't be used.
				</h1>
				<div className="tabs is-toggle is-fullwidth is-centered">
					<ul>
						<li className={isActive(Tabs.Provider)}>
							{/* eslint-disable-next-line */}
							<a onClick={handleTabClick(Tabs.Provider)}>Providers</a>
						</li>
						<li className={isActive(Tabs.Facility)}>
							{/* eslint-disable-next-line */}
							<a onClick={handleTabClick(Tabs.Facility)}>Facilities</a>
						</li>
					</ul>
				</div>
			</div>
			<div className="mt-4">
				<TabContent />
			</div>
		</>
	);
};
